export default class StorageManager {
	/**
	 * Retrieve a value (including objects) from localStorage, sessionStorage, or cookies.
	 * @param {'local' | 'session' | 'cookie'} type - The storage type.
	 * @param {string} key - The key to retrieve.
	 * @returns {any|null} - The stored value (object, string, etc.) or null.
	 */
	get(type, key) {
		let value = null
		switch (type) {
			case 'local':
				value = localStorage.getItem(key)
				break
			case 'session':
				value = sessionStorage.getItem(key)
				break
			case 'cookie':
				value = this._getCookie(key)
				break
			default:
				console.error('Invalid storage type. Use "local", "session", or "cookie".')
				return null
		}

		try {
			return JSON.parse(value) // Attempt to parse JSON (handles objects)
		} catch (e) {
			console.warn('Error parsing JSON:', e)
			return value // Return as-is if not JSON
		}
	}

	/**
	 * Set or merge a value in localStorage, sessionStorage, or cookies.
	 * @param {'local' | 'session' | 'cookie'} type - The storage type.
	 * @param {string} key - The key to set.
	 * @param {any} value - The value to store (object, string, etc.).
	 * @param {number} [days] - Number of days before the cookie expires (cookies only).
	 * @param {boolean} [merge] - Whether to merge the value with existing data.
	 */
	set(type, key, value, days, merge = true) {
		const existingValue = this.get(type, key)

		// Merge if existing value is an object and merging is enabled
		if (merge && typeof existingValue === 'object' && existingValue !== null) {
			value = { ...existingValue, ...value }
		}

		const stringValue = JSON.stringify(value) // Serialize to JSON
		switch (type) {
			case 'local':
				localStorage.setItem(key, stringValue)
				break
			case 'session':
				sessionStorage.setItem(key, stringValue)
				break
			case 'cookie':
				this._setCookie(key, stringValue, days)
				break
			default:
				console.error('Invalid storage type. Use "local", "session", or "cookie".')
		}
	}

	/**
	 * Private method to get a cookie value.
	 * @param {string} key - The cookie name.
	 * @returns {string|null} - The cookie value or null.
	 * @private
	 */
	_getCookie(key) {
		const cookies = document.cookie.split('; ')
		const cookie = cookies.find((row) => row.startsWith(`${key}=`))
		return cookie ? decodeURIComponent(cookie.split('=')[1]) : null
	}

	/**
	 * Private method to set a cookie with an expiration date.
	 * @param {string} key - The cookie name.
	 * @param {string} value - The value to store.
	 * @param {number} [days] - Number of days until the cookie expires.
	 * @private
	 */
	_setCookie(key, value, days = 7) {
		const expires = new Date()
		expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000)
		document.cookie = `${key}=${encodeURIComponent(value)};expires=${expires.toUTCString()};path=/;SameSite=Strict`
	}
}
