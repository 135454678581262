import StorageManager from 'shared/utils/storage'

/**
 * Top bar class
 * @module TopBar
 */
export default class TopBar {
	/**
	 * Constructs a new instance of TopBar.
	 */
	constructor() {
		this.container = document.querySelector('.topBar')
		this.subscribeButton = this.container?.querySelector('.topBar-newsletter')
		this.htmlTag = document.querySelector('html')
		this.storage = new StorageManager()

		this.onClick = this.onClick.bind(this)
	}

	/**
	 * Initializes the top bar
	 */
	init() {
		if (!this.container) return

		this.addEvents()
	}

	/**
	 * Adds events
	 */
	addEvents() {
		if (this.subscribeButton) {
			this.subscribeButton.addEventListener('click', this.onClick)
		}
	}

	/**
	 * Handles the click event for the subscribe button
	 */
	onClick() {
		if (!document.querySelector('.modalNewsletter')) {
			document.dispatchEvent(new CustomEvent('newsletterModal'))
		} else {
			document.dispatchEvent(new CustomEvent('show-newsletter-modal'))
		}
	}
}
