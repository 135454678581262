import StorageManager from 'shared/utils/storage.js'
import isTimestampExpired from 'shared/utils/is-timestamp-expired.js'

/**
 * Dynamically imports and handles the modal newsletter logic.
 */
export default function DynamicImportModalNewsletter() {
	const cmpConsentCookieKey = document.body.getAttribute('data-cmp-consent')
	if (!cmpConsentCookieKey) {
		console.warn('CMP consent cookie key is missing.')
		return
	}

	const storage = new StorageManager()
	const localStorageData = storage.get('local', 'ffu') || {}
	const modalNewsletterStorage = localStorageData.modalNewsletter || null
	const isTimestampValid = modalNewsletterStorage
		? isTimestampExpired({ timestamp: modalNewsletterStorage.timestamp, days: 30 })
		: true

	const dontShow = modalNewsletterStorage?.dontShow || false
	const canBeDisplayed = !!storage.get('cookie', cmpConsentCookieKey)

	/**
	 * Dynamically imports the modal newsletter configuration.
	 */
	const importModalNewsletter = async () => {
		try {
			await import(
				/* webpackChunkName: "dynamic-import-newsletter-modal" */
				'shared/modals/components/modal-newsletter/config.js'
			)
		} catch (error) {
			console.error('Failed to dynamically import the modal newsletter:', error)
		}
	}

	// Import the modal newsletter if conditions are met
	if (canBeDisplayed && isTimestampValid && !dontShow) {
		importModalNewsletter()
	}

	// Listen for custom event to trigger modal import
	document.addEventListener('newsletterModal', () => {
		importModalNewsletter()
	})
}
