import StorageManager from 'shared/utils/storage'

export default class DarkModeHandler {
	/**
	 * Constructs a new instance of DarkModeButton.
	 */
	constructor() {
		this.darkModeButtons = [...document.querySelectorAll('.darkmode-button')]
		this.htmlTag = document.querySelector('html')
		this.storage = new StorageManager()
		this.isDarkMode = false

		this.toggleDarkMode = this.toggleDarkMode.bind(this)
	}

	/**
	 * Initializes the dark mode button
	 */
	init() {
		if (!this.darkModeButtons.length) return
		this.checkDarkMode()
		this.addEvents()
	}

	/**
	 * Adds event listeners for the dark mode button
	 */
	addEvents() {
		this.darkModeButtons.forEach((button) => {
			button.addEventListener('click', this.toggleDarkMode)
		})
	}

	/**
	 * Checks if dark mode is enabled and updates UI accordingly
	 */
	checkDarkMode() {
		const storedSettings = this.storage.get('local', 'ffu') || {}
		this.isDarkMode = storedSettings.darkMode || false

		if (this.isDarkMode) {
			this.htmlTag.setAttribute('data-theme', 'dark')
		} else {
			this.htmlTag.removeAttribute('data-theme')
		}

		this.darkModeButtons.forEach((button) => {
			button.classList.toggle('active', this.isDarkMode)
		})
	}

	/**
	 * Toggles dark mode on or off
	 */
	toggleDarkMode() {
		this.isDarkMode = !this.isDarkMode

		if (this.isDarkMode) {
			this.htmlTag.setAttribute('data-theme', 'dark')
		} else {
			this.htmlTag.removeAttribute('data-theme')
		}

		this.darkModeButtons.forEach((button) => {
			button.classList.toggle('active', this.isDarkMode)
		})

		// Update storage
		this.storage.set('local', 'ffu', { darkMode: this.isDarkMode })
	}
}
