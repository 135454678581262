import StorageManager from 'shared/utils/storage.js'

/**
 * Importing the CMP (Consent Management Platform) script.
 */
export default function DynamicImportCmp() {
	const storage = new StorageManager()
	const storageKey = document.querySelector('body').getAttribute('data-cmp-consent')
	const canBeDisplayed = !storage.get('cookie', storageKey)

	const importCmp = async () => {
		import(/* webpackChunkName: "dynamic-import-cmp" */ 'shared/cmp/config.js').then(() => {
			document.dispatchEvent(new CustomEvent('orejimeReady'))
		})
	}

	if (canBeDisplayed) {
		importCmp()
	}

	document.addEventListener('loadCmp', () => {
		importCmp()
	})
}
