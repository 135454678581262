import smoothscroll from 'smoothscroll-polyfill'

export default class BackToTop {
	constructor() {
		this.button = document.querySelector('.backToTop')
		this.toggleButton = this.toggleButton.bind(this)
		this.scrollToTop = this.scrollToTop.bind(this)
		this.offset = 300
	}

	init() {
		smoothscroll.polyfill()
		this.addEvents()
	}

	addEvents() {
		window.addEventListener('scroll', this.toggleButton)
		this.button?.addEventListener('click', this.scrollToTop)
	}

	toggleButton() {
		this.button.classList[window.scrollY > this.offset ? 'add' : 'remove']('show')
	}

	scrollToTop() {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		})
	}
}
